// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-repositories-js": () => import("./../../../src/pages/repositories.js" /* webpackChunkName: "component---src-pages-repositories-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blogs-categories-js": () => import("./../../../src/templates/blogs/categories.js" /* webpackChunkName: "component---src-templates-blogs-categories-js" */),
  "component---src-templates-blogs-post-js": () => import("./../../../src/templates/blogs/post.js" /* webpackChunkName: "component---src-templates-blogs-post-js" */),
  "component---src-templates-works-work-js": () => import("./../../../src/templates/works/work.js" /* webpackChunkName: "component---src-templates-works-work-js" */)
}

